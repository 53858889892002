.selectControl {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    min-width: 16em;
    padding: 0.5em 0.8em;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-small);

    &.expanded {
        border: 1px solid var(--color-input-outline);
    }

    .selectValue {
        display: flex;
        position: relative;
        flex: 1;
        flex-direction: row;
        align-items: center;

        .values {
            flex-wrap: wrap;
            gap: 0.3em;
            display: flex;
        }

        .othersText {
            margin-left: 1em;
        }
    }

    .selectIndicator {
        margin-left: 1em;

        .clear,
        .loading {
            animation: spin infinite 2s linear;
            margin: 0 8px;
        }
    }
}

.value {
    display: flex;
    align-items: center;
    background-color: var(--color-list-highlight-alt);
    border-radius: 2px;

    .label {
        padding: 0.1em 0.5em 0.1em 0.5em;
        font-size: var(--font-size-normal);
    }

    .close {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        padding: 0 0.2em;

        &:hover {
            background-color: darkgray;
        }

        .icon {
            color: var(--color-text);
            font-size: var(--font-size-normal);
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
