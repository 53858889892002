.selectContainer {
    display: flex;
    position: relative;

    &.disabled {
        pointer-events: none;
        filter: brightness(90%);
    }

    .selectControl {
        display: flex;
        flex: 1;
        flex-direction: row;
        padding: 0.5em 0.8em;
        border: 1px solid var(--color-border);
        border-radius: var(--border-radius-small);

        &:hover,
        &.selected {
            border-color: var(--color-input-outline);
        }

        &.warning:not(:hover) {
            border-color: var(--color-warning);
        }

        &.error:not(:hover) {
            border-color: var(--color-danger);
        }

        .input {
            width: 100%;
            padding: none;
            border: none;
            outline: none;
            color: inherit;
        }

        .selectValue {
            display: flex;
            position: relative;
            flex: 1;
            flex-direction: row;
            align-items: center;

            .input {
                position: absolute;
                padding: 0;
                left: 0;
            }

            .value {
                flex: 1;
            }
        }

        .selectIndicator {
            margin-left: 0.5em;
            display: flex;
            align-items: center;

            .clear {
                margin: 0 0.5em;
            }

            .loading {
                animation: spin infinite 2s linear;
                margin: 0 0.5em;
            }
        }
    }

    .placeholder {
        color: lightgray;
    }
}

.popup {
    .selectOptionsWrapper {
        background-color: var(--color-background);
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 11px;
        border-radius: var(--border-radius-small);

        .selectOptions {
            max-height: 12em;
            overflow-y: scroll;
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    .selectOption {
        cursor: pointer;
    }
}

.errorText {
    color: var(--color-danger);
    font-size: 12px;
}

.warningText {
    color: var(--color-warning);
    font-size: 12px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
