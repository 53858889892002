.container {
  width: 1em;
  height: 1em;
  position: relative;
  cursor: pointer;
  user-select: none;

  .inputError {
    border: 1px solid var(--color-danger);
  }

  .inputWarning {
    border: 1px solid var(--color-warning);
  }

  .input {
    font-size: 1em;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1em;
    width: 1em;

    &:checked ~ .checkbox {
      background-color: var(--color-primary);
      border-color: var(--color-primary);

      &::after {
        display: block;
      }
    }

    &:indeterminate ~ .checkbox {
      background-color: var(--color-primary);
      border-color: var(--color-primary);

      &::after {
        display: block;
        width: 0;
        transform: rotate(90deg);
      }
    }
  }

  .checkbox {
    font-size: 1em;
    position: absolute;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    border: 1px solid var(--color-border);
    border-radius: 2px;
    pointer-events: none;
    transition: 0.3s;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 0.36em;
      top: 0.17em;
      width: 0.23em;
      height: 0.48em;
      border: solid white;
      border-width: 0 0.1em 0.1em 0;
      transform: rotate(45deg);
    }
  }

  &:hover,
  &:focus .checkbox {
    border-color: var(--color-input-outline);
  }
}

.errorText {
  color: var(--color-danger);
  font-size: 12px;
}

.warningText {
  color: var(--color-warning);
  font-size: 12px;
}

.infoText {
  color: var(--color-grey);
  font-size: 12px;
}
