@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DMSans-normal';
  src: url('./assets/fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DMSans-bold';
  src: url('./assets/fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'Inter-normal';
  src: url('./assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-medium';
  src: url('./assets/fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter-semibold';
  src: url('./assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter-bold';
  src: url('./assets/fonts/Inter-Bold.ttf');
}

::-ms-reveal {
  display: none;
}
