@import '@ra/styles/base';

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-display: block;
}

.material-symbols-rounded {
  user-select: none;
}

:root {
  --color-primary: #196297;
  --color-input-outline: #3374A2;
}

.select-control {
  color: #585D69;
}

.select-indicator {
  display: flex;
  align-items: center;

  svg[class*="clear"] {
    background-color: #9FA3A9;
    border-radius: 50%;
    padding: 3px;

    path {
      color: white;
    }
  }

}

.mapboxgl-map {
  border-radius: 8px;
}

.mapboxgl-popup-close-button {
  font-weight: bold;
  font-size: 16px;
  width: 24px;
  height: 24px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
