.option {
  display: flex;
  gap: 0.5em;
  padding: 0.5em 0 0.5em 0.3em;
  align-items: center;

  &:hover {
    background-color: var(--color-list-highlight);
  }
}
