.container {
  z-index: 1;
  margin-bottom: 1em;

  .accordion-section {
    display: grid;
    background-color: #fff;
    padding: 1.875em 2.5em;
    border-radius: 0.5em;

    @media screen and (max-width: 768px) {
      padding: 1em;
    }

    .accordion {
      color: var(--color-text);
      background-color: transparent;
      border-color: transparent;
      outline: none;
      padding: 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.3s ease;
      text-align: left;

      .accordion-title {
        font-size: var(--font-size-medium);
        color: var(--color-text-dark-alt);
      }

      .active-title {
        color: var(--color-primary);
      }

      .down-icon {
        font-size: var(--font-size-medium);
        color: var(--color-primary);
        transition: transform 0.3s ease;
      }

      .rotate-up {
        transition: 0.5s;
        transform: rotate(90deg);
      }
    }

    .accordion:focus {
      outline: none !important;
    }

    .accordion-content {
      overflow: hidden;
      transition: max-height 0.3s ease;

      .accordion-text {
        text-align: left;
        padding-top: 1.25em;
        font-size: var(--font-size-medium);
        line-height: 1.5;
        transition: 0.3s;
        color: var(--color-text-dark-alt);
        overflow-x: auto;

        table, th, td {
          border: 1px solid #f4f4f4;
        }
        th, td {
          padding: 0.5em;
        }
      }
      a {
        color: #0724cb;
      }
    }
  }

  .active {
    background-color: rgba(#fff, 1);
    box-shadow: 5px 20px 45px 0 rgba(0, 0, 0, 0.04);
  }
}
