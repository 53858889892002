.button {
  padding: 0.4em 0.8em;
  font-size: var(--font-size-normal);
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-small);
  transition: all ease-in 0.2s;
  cursor:pointer;

  &.success {
    background-color: var(--color-success);
    color: var(--color-success-text);
    border-color: var(--color-success);
  }

  &.warning {
    background-color: var(--color-warning);
    color: var(--color-warning-text);
    border-color: var(--color-warning);
  }

  &.danger {
    background-color: var(--color-danger);
    color: var(--color-danger-text);
    border-color: var(--color-danger);
  }

  &.disabled {
    pointer-events: none;
    filter: opacity(60%);
  }

  &.outline:not(:hover) {
    color: var(--color-primary);
    background-color: var(--color-background);

    &.success {
      color: var(--color-success);
    }

    &.warning {
      color: var(--color-warning);
    }

    &.danger {
      color: var(--color-danger);
    }
  }

  &:not(.disabled):not(.outline):hover {
    filter: contrast(150%);
  }
}

