.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 9998;
}

.modal {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 3px rgba(0,0,0,0.20);
    background-color: lightgray;
    max-height: 96vh;
    z-index: 9999;
}
